function BuyButton({payeerProps, productId, cost}){
	const {m_shop, m_orderid, m_amount, m_curr, m_desc, sign} = payeerProps;
	
	return (
	  <form method="post" action="https://payeer.com/merchant/">
		<input type="hidden" name="m_shop" value="{m_shop}"/>
		<input type="hidden" name="m_orderid" value="{m_orderid}"/>
		<input type="hidden" name="m_amount" value="{m_amount}"/>
		<input type="hidden" name="m_curr" value="{m_curr}"/>
		<input type="hidden" name="m_desc" value="{m_desc}"/>
		<input type="hidden" name="m_sign" value="{sign}"/>
			{
			/*
			<input type="hidden" name="form[ps]" value="2609">
			<input type="hidden" name="form[curr[2609]]" value="USD">
			*/ 
			/*
			<input type="hidden" name="m_params" value="<?=$m_params?>">
			<input type="hidden" name="m_cipher_method" value="AES-256-CBC">
			*/ 
			}
			<button type="submit" name="m_process" class="fa fa-shopping-cart"></button>
			
			{/*<i class="fa fa-shopping-cart">
		<input type="submit" name="m_process" value="" />
			</i>*/}
	  </form>
	);
}

function Home() {
	let m_shop = '';
	let m_orderid = '1';
	let m_amount = 'qwe';//number_format(100, 2, '.', '');
	let m_curr = 'USD';
	let m_desc = 'qwe';//base64_encode('Test');
	let m_key = 'Ваш секретный ключ';
	
	const arHash = [m_shop, m_orderid, m_amount, m_curr, m_desc];
	
	const sign = 'qwe';
	
	const payeerProps={ m_shop, m_orderid, m_amount, m_curr, m_desc, sign };
	
    return (
      <div className="home-main">
            <div class="main-banner" id="top">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="left-content">
                                <div class="thumb">
                                    <div class="inner-content">
                                        <h4>We Are Mega</h4>
                                        <span>Awesome and unique style for you.</span>
                                        <span>Buy NFT and be sure that noone else in the world wearing this!</span>
                                        <div class="main-border-button">
                                            <a href="#men">Purchase Now!</a>
                                        </div>
                                    </div>
                                    <img src="assets/images/left-banner-image.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="right-content">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="right-first-image">
                                            <div class="thumb">
                                                <div class="inner-content">
                                                    <h4>Women</h4>
                                                    <span>Best Style For Women</span>
                                                </div>
                                                <div class="hover-content">
                                                    <div class="inner">
                                                        <h4>Women</h4>
                                                        <p>Best designers created this style for you</p>
                                                        <div class="main-border-button">
                                                            <a href="#women">Discover More</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src="assets/images/baner-right-image-01.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="right-first-image">
                                            <div class="thumb">
                                                <div class="inner-content">
                                                    <h4>Men</h4>
                                                    <span>Best Style For Men</span>
                                                </div>
                                                <div class="hover-content">
                                                    <div class="inner">
                                                        <h4>Men</h4>
                                                        <p>Our styles will perfectly represent your manhood</p>
                                                        <div class="main-border-button">
                                                            <a href="#men">Discover More</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src="assets/images/baner-right-image-02.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="right-first-image">
                                            <div class="thumb">
                                                <div class="inner-content">
                                                    <h4>Kids</h4>
                                                    <span>Best Style For Kids</span>
                                                </div>
                                                <div class="hover-content">
                                                    <div class="inner">
                                                        <h4>Kids</h4>
                                                        <p>This style will emphasize your kids uniqueness</p>
                                                        <div class="main-border-button">
                                                            <a href="#kids">Discover More</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src="assets/images/baner-right-image-03.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="right-first-image">
                                            <div class="thumb">
                                                <div class="inner-content">
                                                    <h4>Accessories</h4>
                                                    <span>Best Trend Accessories</span>
                                                </div>
                                                <div class="hover-content">
                                                    <div class="inner">
                                                        <h4>Accessories</h4>
                                                        <p>Express yourself in every detail</p>
                                                        <div class="main-border-button">
                                                            <a href="#explore">Discover More</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src="assets/images/baner-right-image-04.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <section class="section" id="men">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="section-heading">
                                <h2>Men's Best</h2>
                                <span>Details to details is what makes Mega different from the other themes.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="men-item-carousel">
                                <div class="owl-men-item owl-carousel">
                                    <div class="item">
                                        <div class="thumb">
                                            <div class="hover-content">
                                                <ul>
												{/*
                                                    <li><a href="single-product"><i class="fa fa-eye"></i></a></li>
                                                    <li><a href="single-product"><i class="fa fa-star"></i></a></li>
												*/}
                                                    <li>
													<BuyButton
														payeerProps={payeerProps}
														productId="d1d3d919-7d45-47e2-9b9a-7b2d0fd4b530" 
														cost="520"
													/>
													{/*<a href="single-product"><i class="fa fa-shopping-cart"></i></a>*/}
													</li>
                                                </ul>
                                            </div>
                                            <img src="assets/images/men-01.jpg" alt="" />
                                        </div>
                                        <div class="down-content">
                                            <h4>Classic Spring</h4>
                                            <span>$520.00</span>
                                            <ul class="stars">
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="thumb">
                                            <div class="hover-content">
                                                <ul>
												{/*
                                                    <li><a href="single-product"><i class="fa fa-eye"></i></a></li>
                                                    <li><a href="single-product"><i class="fa fa-star"></i></a></li>
												*/}
                                                    <li>
													<BuyButton
														payeerProps={payeerProps}
														productId="066bfdc5-5f05-48ef-b16a-047d76b963c5" 
														cost="190"
													/></li>
                                                </ul>
                                            </div>
                                            <img src="assets/images/men-02.jpg" alt="" />
                                        </div>
                                        <div class="down-content">
                                            <h4>Air Force 1 X</h4>
                                            <span>$190.00</span>
                                            <ul class="stars">
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="thumb">
                                            <div class="hover-content">
                                                <ul>
												{/*
                                                    <li><a href="single-product"><i class="fa fa-eye"></i></a></li>
                                                    <li><a href="single-product"><i class="fa fa-star"></i></a></li>
												*/}
                                                    <li>
														<BuyButton
															payeerProps={payeerProps}
															productId="454f0efa-7fdf-45f0-b80d-d8f050bc5fa5" 
															cost="350"
														/>
													</li>
                                                </ul>
                                            </div>
                                            <img src="assets/images/men-03.jpg" alt="" />
                                        </div>
                                        <div class="down-content">
                                            <h4>Love Nana ‘20</h4>
                                            <span>$350.00</span>
                                            <ul class="stars">
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          
            <section class="section" id="women">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="section-heading">
                                <h2>Women's Best</h2>
                                <span>Details to details is what makes Mega different from the other themes.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="women-item-carousel">
                                <div class="owl-women-item owl-carousel">
                                    <div class="item">
                                        <div class="thumb">
                                            <div class="hover-content">
                                                <ul>
												{/*
                                                    <li><a href="single-product"><i class="fa fa-eye"></i></a></li>
                                                    <li><a href="single-product"><i class="fa fa-star"></i></a></li>
												*/}
                                                    <li>
														<BuyButton
															payeerProps={payeerProps}
															productId="dbcf7c16-035a-414b-8f65-6c3c2baf1062" 
															cost="475"
														/>
													</li>
                                                </ul>
                                            </div>
                                            <img src="assets/images/women-01.jpg" alt="" />
                                        </div>
                                        <div class="down-content">
                                            <h4>Strict Freedom</h4>
                                            <span>$475.00</span>
                                            <ul class="stars">
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="thumb">
                                            <div class="hover-content">
                                                <ul>
												{/*
                                                    <li><a href="single-product"><i class="fa fa-eye"></i></a></li>
                                                    <li><a href="single-product"><i class="fa fa-star"></i></a></li>
												*/}
                                                    <li>
														<BuyButton
															payeerProps={payeerProps}
															productId="282c92d9-28fc-4d44-ba11-9878bd7b59cf" 
															cost="345"
														/>
													</li>
                                                </ul>
                                            </div>
                                            <img src="assets/images/women-02.jpg" alt="" />
                                        </div>
                                        <div class="down-content">
                                            <h4>Classic Dress</h4>
                                            <span>$345.00</span>
                                            <ul class="stars">
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="thumb">
                                            <div class="hover-content">
                                                <ul>
												{/*
                                                    <li><a href="single-product"><i class="fa fa-eye"></i></a></li>
                                                    <li><a href="single-product"><i class="fa fa-star"></i></a></li>
												*/}
                                                    <li>
														<BuyButton
															payeerProps={payeerProps}
															productId="736502a0-5cbc-45b7-aca5-ae10c8208325" 
															cost="530"
														/>
													</li>
                                                </ul>
                                            </div>
                                            <img src="assets/images/women-03.jpg" alt="" />
                                        </div>
                                        <div class="down-content">
                                            <h4>Spring Collection</h4>
                                            <span>$530.00</span>
                                            <ul class="stars">
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          
            <section class="section" id="kids">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="section-heading">
                                <h2>Kid's Latest</h2>
                                <span>Details to details is what makes Mega different from the other themes.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="kid-item-carousel">
                                <div class="owl-kid-item owl-carousel">
                                    <div class="item">
                                        <div class="thumb">
                                            <div class="hover-content">
                                                <ul>
												{/*
                                                    <li><a href="single-product"><i class="fa fa-eye"></i></a></li>
                                                    <li><a href="single-product"><i class="fa fa-star"></i></a></li>
												*/}
                                                    <li>
														<BuyButton
															payeerProps={payeerProps}
															productId="330613bc-2771-4aab-8cd9-86151489694c" 
															cost="280"
														/>
													</li>
                                                </ul>
                                            </div>
                                            <img src="assets/images/kid-01.jpg" alt="" />
                                        </div>
                                        <div class="down-content">
                                            <h4>School Collection</h4>
                                            <span>$280.00</span>
                                            <ul class="stars">
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="thumb">
                                            <div class="hover-content">
                                                <ul>
												{/*
                                                    <li><a href="single-product"><i class="fa fa-eye"></i></a></li>
                                                    <li><a href="single-product"><i class="fa fa-star"></i></a></li>
												*/}
                                                    <li>
														<BuyButton
															payeerProps={payeerProps}
															productId="1d0fe21d-8011-4069-9fee-e6a1389aaac5" 
															cost="120"
														/>
													</li>
                                                </ul>
                                            </div>
                                            <img src="assets/images/kid-02.jpg" alt="" />
                                        </div>
                                        <div class="down-content">
                                            <h4>Summer Cap</h4>
                                            <span>$120.00</span>
                                            <ul class="stars">
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="thumb">
                                            <div class="hover-content">
                                                <ul>
												{/*
                                                    <li><a href="single-product"><i class="fa fa-eye"></i></a></li>
                                                    <li><a href="single-product"><i class="fa fa-star"></i></a></li>
												*/}
                                                    <li>
														<BuyButton
															payeerProps={payeerProps}
															productId="ece8888b-2a1c-4d70-aaef-6b3fd17a005d" 
															cost="130"
														/>
													</li>
                                                </ul>
                                            </div>
                                            <img src="assets/images/kid-03.jpg" alt="" />
                                        </div>
                                        <div class="down-content">
                                            <h4>Classic Kid</h4>
                                            <span>$130.00</span>
                                            <ul class="stars">
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                                <li><i class="fa fa-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
         
            <section class="section" id="explore">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="left-content">
                                <h2>Explore Our Accessories</h2>
                                <span>Accessories provide interest to your outfit. Without them, you run the risk of getting bored with your wardrobe, and your outfits looking boring, because they're always the same.</span>
                                <div class="quote">
                                    <i class="fa fa-quote-left"></i><p>Accessories extend the life of your clothes and familiar ensembles</p>
                                </div>
                                <p>Right now we are working on our new collection. It will be published next month</p>
                                {/*<div class="main-border-button">
                                    <a href="products.html">Discover More</a>
                                </div>*/}
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="right-content">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="leather">
                                            <h4>Leather Bags</h4>
                                            <span>Latest Collection</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="first-image">
                                            <img src="assets/images/explore-image-01.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="second-image">
                                            <img src="assets/images/explore-image-02.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="types">
                                            <h4>Different Types</h4>
                                            <span>For every taste</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
			{/*
            <section class="section" id="social">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section-heading">
                                <h2>Social Media</h2>
                                <span>Details to details is what makes Therichpost different from the other themes.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row images">
                        <div class="col-2">
                            <div class="thumb">
                                <div class="icon">
                                    <a href="http://instagram.com">
                                        <h6>Fashion</h6>
                                        <i class="fa fa-instagram"></i>
                                    </a>
                                </div>
                                <img src="assets/images/instagram-01.jpg" alt="" />
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="thumb">
                                <div class="icon">
                                    <a href="http://instagram.com">
                                        <h6>New</h6>
                                        <i class="fa fa-instagram"></i>
                                    </a>
                                </div>
                                <img src="assets/images/instagram-02.jpg" alt="" />
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="thumb">
                                <div class="icon">
                                    <a href="http://instagram.com">
                                        <h6>Brand</h6>
                                        <i class="fa fa-instagram"></i>
                                    </a>
                                </div>
                                <img src="assets/images/instagram-03.jpg" alt="" />
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="thumb">
                                <div class="icon">
                                    <a href="http://instagram.com">
                                        <h6>Makeup</h6>
                                        <i class="fa fa-instagram"></i>
                                    </a>
                                </div>
                                <img src="assets/images/instagram-04.jpg" alt="" />
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="thumb">
                                <div class="icon">
                                    <a href="http://instagram.com">
                                        <h6>Leather</h6>
                                        <i class="fa fa-instagram"></i>
                                    </a>
                                </div>
                                <img src="assets/images/instagram-05.jpg" alt="" />
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="thumb">
                                <div class="icon">
                                    <a href="http://instagram.com">
                                        <h6>Bag</h6>
                                        <i class="fa fa-instagram"></i>
                                    </a>
                                </div>
                                <img src="assets/images/instagram-06.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
			*/}
        
            <div class="subscribe">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="section-heading">
                                <h2>By Subscribing To Our Newsletter You Can Get 10% Off</h2>
                                <span>Details to details is what makes Mega different from the other themes.</span>
                            </div>
                            <form id="subscribe" action="javascript:setTimeout(() => alert('Thank you for subscription!'), 1000)">
                                <div class="row">
                                <div class="col-lg-5">
                                    <fieldset>
                                    <input name="name" type="text" id="name" placeholder="Your Name" required="" />
                                    </fieldset>
                                </div>
                                <div class="col-lg-5">
                                    <fieldset>
                                    <input name="email" type="text" id="email" pattern="[^ @]*@[^ @]*" placeholder="Your Email Address" required="" />
                                    </fieldset>
                                </div>
                                <div class="col-lg-2">
                                    <fieldset>
                                    <button type="submit" id="form-submit" class="main-dark-button"><i class="fa fa-paper-plane"></i></button>
                                    </fieldset>
                                </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="col-12">
                                    <ul>
                                        <li>Store Brand:<br /><span>Mega Mramor</span></li>
                                        <li>Phone:<br /><span>+7-747-109-48-63</span></li>
                                        <li>Email:<br /><span>info@mega-mramor.com</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Home;