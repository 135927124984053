function Contact() {
    return (
      <div className="contact-main">
             
           
            
                <div class="page-heading about-page-heading" id="top">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="inner-content">
                                    <h2>Contact Us</h2>
                                    <span>Awesome, unique &amp; creative styles for you</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				{/*
                <div class="contact-us">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <div id="map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d90186.37207676383!2d-80.13495239500924!3d25.9317678710111!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9ad1877e4a82d%3A0xa891714787d1fb5e!2sPier%20Park!5e1!3m2!1sen!2sth!4v1637512439384!5m2!1sen!2sth" width="100%" height="400px" frameborder="0" style={{border:"0"}} allowfullscreen></iframe>
                               
                                
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="section-heading">
                                    <h2>Say Hello. Don't Be Shy!</h2>
                                    <span>Details to details is what makes Therichpost different from the other themes.</span>
                                </div>
                                <form id="contact" action="" method="post">
                                    <div class="row">
                                    <div class="col-lg-6">
                                        <fieldset>
                                        <input name="name" type="text" id="name" placeholder="Your name" required="" />
                                        </fieldset>
                                    </div>
                                    <div class="col-lg-6">
                                        <fieldset>
                                        <input name="email" type="text" id="email" placeholder="Your email" required="" />
                                        </fieldset>
                                    </div>
                                    <div class="col-lg-12">
                                        <fieldset>
                                        <textarea name="message" rows="6" id="message" placeholder="Your message" required=""></textarea>
                                        </fieldset>
                                    </div>
                                    <div class="col-lg-12">
                                        <fieldset>
                                        <button type="submit" id="form-submit" class="main-dark-button"><i class="fa fa-paper-plane"></i></button>
                                        </fieldset>
                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                */}
                <div class="subscribe">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8">
                            <div class="section-heading">
                                <h2>By Subscribing To Our Newsletter You Can Get 10% Off</h2>
                                <span>Details to details is what makes Mega different from the other themes.</span>
                            </div>
                            <form id="subscribe" action="javascript:setTimeout(() => alert('Thank you for subscription!'), 1000)">
                                <div class="row">
                                <div class="col-lg-5">
                                    <fieldset>
                                    <input name="name" type="text" id="name" placeholder="Your Name" required="" />
                                    </fieldset>
                                </div>
                                <div class="col-lg-5">
                                    <fieldset>
                                    <input name="email" type="text" id="email" pattern="[^ @]*@[^ @]*" placeholder="Your Email Address" required="" />
                                    </fieldset>
                                </div>
                                <div class="col-lg-2">
                                    <fieldset>
                                    <button type="submit" id="form-submit" class="main-dark-button"><i class="fa fa-paper-plane"></i></button>
                                    </fieldset>
                                </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="col-12">
                                    <ul>
                                        <li>Store Brand:<br /><span>Mega Mramor</span></li>
                                        <li>Phone:<br /><span>+7-747-109-48-63</span></li>
                                        <li>Email:<br /><span>info@mega-mramor.com</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
             
           
          
        </div>
    )
}
export default Contact;