function About() {
    return (
      <div className="about-main">
             
            <div class="page-heading about-page-heading" id="top">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-content">
                                <h2>About Our Company</h2>
                                <span>Awesome, unique &amp; creative styles for you</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="about-us">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="left-image">
                                <img src="assets/images/about-left-image.jpg" alt="" />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="right-content">
                                <h4>About Us &amp; Our Skills</h4>
                                <span>We are the team of inspired designers and developers. We want to create unique style which will express yourself in a best way.</span>
                                <div class="quote">
                                    <i class="fa fa-quote-left"></i><p>Don't be into trends. Don't make fashion own you, but you decide what you are, what you want to express by the way you dress and the way to live</p>
									- Gianni Versace
                                </div>
                                <p>We guarantee that no one else in the world will have same style.</p>
                                {/*<ul>
                                    <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                    <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                    <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                    <li><a href="#"><i class="fa fa-behance"></i></a></li>
                                </ul>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			{/*
            <section class="our-team">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section-heading">
                                <h2>Our Amazing Team</h2>
                                <span>We inspired to create unique.</span>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="team-item">
                                <div class="thumb">
                                    <div class="hover-effect">
                                        <div class="inner-content">
                                            <ul>
                                                <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                <li><a href="#"><i class="fa fa-behance"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <img src="assets/images/team-member-01.jpg" />
                                </div>
                                <div class="down-content">
                                    <h4>Ragnar Lodbrok</h4>
                                    <span>Main Designer</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="team-item">
                                <div class="thumb">
                                    <div class="hover-effect">
                                        <div class="inner-content">
                                            <ul>
                                                <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                <li><a href="#"><i class="fa fa-behance"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <img src="assets/images/team-member-02.jpg" />
                                </div>
                                <div class="down-content">
                                    <h4>Ragnar Lodbrok</h4>
                                    <span>Product Caretaker</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="team-item">
                                <div class="thumb">
                                    <div class="hover-effect">
                                        <div class="inner-content">
                                            <ul>
                                                <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                                <li><a href="#"><i class="fa fa-behance"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <img src="assets/images/team-member-03.jpg" />
                                </div>
                                <div class="down-content">
                                    <h4>Ragnar Lodbrok</h4>
                                    <span>Product Caretaker</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
			*/}
           
            <section class="our-services">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section-heading">
                                <h2>Our Services</h2>
                                <span>Details to details is what makes Therichpost different from the other themes.</span>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="service-item">
                                <h4>Create style</h4>
                                <p>Our designers inspired by latest fashion trends. Combine their experience, knowledge and sense of perfection in product. Just for you.</p>
                                <img src="assets/images/service-01.jpg" alt="" />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="service-item">
                                <h4>Sell you NFT</h4>
                                <p>We sell you NFT to give you ownership on style. You can use it to prove that your cloth is unique and no one else can use it.</p>
                                <img src="assets/images/service-02.jpg" alt="" />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="service-item">
                                <h4>Guarantee uniq</h4>
                                <p>We guarantee that these clothing drawings wouldn't be used to product more cloths and each instance will be sold to each buyer once.</p>
                                <img src="assets/images/service-03.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
         
            <div class="subscribe">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="section-heading">
                                <h2>By Subscribing To Our Newsletter You Can Get 10% Off</h2>
                                <span>Details to details is what makes Mega different from the other themes.</span>
                            </div>
                            <form id="subscribe" action="javascript:setTimeout(() => alert('Thank you for subscription!'), 1000)">
                                <div class="row">
                                <div class="col-lg-5">
                                    <fieldset>
                                    <input name="name" type="text" id="name" placeholder="Your Name" required="" />
                                    </fieldset>
                                </div>
                                <div class="col-lg-5">
                                    <fieldset>
                                    <input name="email" type="text" id="email" pattern="[^ @]*@[^ @]*" placeholder="Your Email Address" required="" />
                                    </fieldset>
                                </div>
                                <div class="col-lg-2">
                                    <fieldset>
                                    <button type="submit" id="form-submit" class="main-dark-button"><i class="fa fa-paper-plane"></i></button>
                                    </fieldset>
                                </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="col-12">
                                    <ul>
                                        <li>Store Brand:<br /><span>Mega Mramor</span></li>
                                        <li>Phone:<br /><span>+7-747-109-48-63</span></li>
                                        <li>Email:<br /><span>info@mega-mramor.com</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </div>
    )
}
export default About;