function Footer() {
    return (
      
        <footer>
            <div class="container">
                <div class="row">
				{/*
                    <div class="col-lg-3">
                        <div class="first-item">
                            <div class="logo">
                                therichpost
                            </div>
                            <ul>
                                <li><a href="#">Ludhiana Punjab India</a></li>
                                <li><a href="#">Therichpost@company.com</a></li>
                                <li><a href="#">12345678910</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <h4>Shopping &amp; Categories</h4>
                        <ul>
                            <li><a href="#">Men’s Shopping</a></li>
                            <li><a href="#">Women’s Shopping</a></li>
                            <li><a href="#">Kid's Shopping</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-3">
                        <h4>Useful Links</h4>
                        <ul>
                            <li><a href="#">Homepage</a></li>
                            <li><a href="#">About Us</a></li>
                            <li><a href="#">Help</a></li>
                            <li><a href="#">Contact Us</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-3">
                        <h4>Help &amp; Information</h4>
                        <ul>
                            <li><a href="#">Help</a></li>
                            <li><a href="#">FAQ's</a></li>
                            <li><a href="#">Shipping</a></li>
                            <li><a href="#">Tracking ID</a></li>
                        </ul>
                    </div>
				*/}
                    <div class="col-lg-12">
                        <div class="under-footer">
                            <p>Copyright © 2022 Mega Mramor Co., Ltd. All Rights Reserved. 
                            
                            <br/>Design: <a href="https://mega-mramor.com" target="_parent" title="best style in all spheres">Mega</a></p>
                            {/*<ul>
                                <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                <li><a href="#"><i class="fa fa-behance"></i></a></li>
                            </ul>*/}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
   );
}
export default Footer;